import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  TextField,
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
  FormGroup,
  Select as MuiSelect,
  Checkbox,
  Dialog
} from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { SelectMultiField } from '../../common';
import { setData } from '../../../redux/dataSlice';
import { baseApiUrl } from '../../../config';

// TODO: specialty and degree should be a tagcloud, if from npi registry and no specialty or degree match then do other and add to other field

const VeloxisRegFormComponent = (props) => {
  const { dropDownValues, isLicensed, npiInfo, meetingInfo, setData, regSource } = props;

  const [formValues, setFormValues] = React.useState({
    meeting_id: meetingInfo.id,
    reg_source: regSource
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [submitted, setSubmitted] = React.useState(false);
  const [submitError, setSubmitError] = React.useState(null);
  const [errModalOpen, setErrModalOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const [npiDataObj, setNpiDataObj] = React.useState({});
  const [attendanceType, setAttendanceType] = React.useState(null);

  React.useEffect(() => {
    if (isLicensed && !_.isEmpty(npiInfo)) {
      let npiData = {};
      if (_.keys(npiInfo).includes('basic')) {
        // data coming from the npi registry
        const { basic, addresses, taxonomies } = npiInfo;

        console.log('data coming from nppes', npiInfo);
        npiData = {
          npi: npiInfo.number,
          firstname: basic.first_name,
          lastname: basic.last_name,
          minitial: basic.middle_name ? basic.middle_name[0] : '',
          address1: addresses[0].address_1 || '',
          address2: addresses[0].address_2 || '',
          city: addresses[0].city || '',
          state: addresses[0].state || '',
          zip: addresses[0].postal_code || '',
          ...computeTagCloudValues(
            _.compact(taxonomies.map((taxonomy) => taxonomy.desc)),
            dropDownValues.specialties,
            'specialty'
          ),
          ...computeTagCloudValues(_.compact([basic.credential]), dropDownValues.degrees, 'degree')
        };
      } else {
        // data coming from the hlx database
        console.log('data coming from the hlx/medpro database', npiInfo);
        npiData = {
          contactId: npiInfo.contactid,
          npi: npiInfo.npinumber,
          firstname: npiInfo.firstname,
          lastname: npiInfo.lastname,
          minitial: npiInfo.middleinitial,
          orgname: npiInfo.orgname,
          address1: npiInfo.orgaddress1,
          address2: npiInfo.orgaddress2,
          phone: npiInfo.phone,
          city: npiInfo.orgcity,
          state: npiInfo.orgstate,
          zip: npiInfo.orgpostalcode,
          email: npiInfo.email,
          ...computeTagCloudValues(
            _.compact(npiInfo.specialty.split(',').map((spclty) => spclty.trim())),
            dropDownValues.specialties,
            'specialty'
          ),
          ...computeTagCloudValues(
            _.compact(npiInfo.medicaldegree.split(',').map((dgr) => dgr.trim())),
            dropDownValues.degrees,
            'degree'
          )
        };
      }
      setNpiDataObj(npiData);
      setFormValues({ ...formValues, ...npiData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const computeTagCloudValues = (incomingValues, incomingdropDownValues, objectKey) => {
    console.log('incoming', objectKey, incomingValues);
    const tagArray = [];
    let otherElem = '';
    let otherTagFlag = false;
    if (incomingValues && incomingValues.length > 0) {
      const otherDropDownElem = _.find(
        incomingdropDownValues,
        (elem) => elem.name.toUpperCase() === 'OTHER'
      );

      incomingValues.forEach((elemName) => {
        if (elemName.toUpperCase() !== 'OTHER') {
          const dropdownElem = _.find(
            incomingdropDownValues,
            (elem) => elem.name.toUpperCase() === elemName.toUpperCase()
          );
          const valueName = dropdownElem && dropdownElem.name;
          if (valueName) {
            tagArray.push({ value: valueName, label: valueName });
          } else {
            if (otherDropDownElem) {
              if (!otherTagFlag) {
                tagArray.push({
                  value: otherDropDownElem.name,
                  label: otherDropDownElem.name
                });
                otherTagFlag = true;
              }
              otherElem = otherElem.length ? (otherElem += `, ${elemName}`) : elemName;
            }
          }
        }
      });
    }
    return { [objectKey]: tagArray, [`other_${objectKey}`]: otherElem };
  };

  const validateFields = () => {
    let requiredFields = [
      'firstname',
      'lastname',
      'specialty',
      'orgname',
      'address1',
      'city',
      'state',
      'zip',
      'phone',
      'email',
      'hcp_certify',
      'email_consent'
    ];
    if (isLicensed) {
      const reqFields = ['npi', 'degree'];
      (formValues.degree || []).forEach((degree) => {
        if (degree.value.toUpperCase() === 'OTHER') {
          reqFields.push('other_degree');
        }
      });
      requiredFields = requiredFields.concat(reqFields);
    } else {
      //requiredFields.push('role');
      requiredFields.push('role');
      if (formValues.role?.toUpperCase() === 'OTH') {
        requiredFields.push('other_role');
      }
    }

    (formValues.specialty || []).forEach((specialty) => {
      if (specialty.value.toUpperCase() === 'OTHER') {
        requiredFields.push('other_specialty');
      }
    });

    const errors = {};
    requiredFields.forEach((field) => {
      if (!formValues[field]) {
        errors[field] = true;
      }
    });
    if (formValues.npi) {
      var x = formValues.npi.toString().length;
      if (!isLicensed && parseInt(x) !== 10) {
        errors['npi'] = true;
      }
    }

    if (formValues.email) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formValues.email)) {
        errors['email'] = true;
      }
    }

    if (meetingInfo.is_virtual_hybrid && !attendanceType) {
      errors['attendanceType'] = true;
    }

    setFormErrors(errors);
    return _.isEmpty(errors) ? true : false;
  };

  const submitForm = async () => {
    setSubmitError(null);
    const isValid = validateFields();
    console.log('isValid', isValid, formErrors);
    formValues.isLicensed = isLicensed;

    if (isValid) {
      console.log('formValues', formValues);
      const formPayload = { ...formValues };

      formPayload.specialty = _.map(formPayload.specialty || [], 'value').join(', ');
      if (formPayload.other_specialty) {
        formPayload.specialty = formPayload.specialty + ', ' + formPayload.other_specialty;
      }
      formPayload.degree = _.map(formPayload.degree || [], 'value').join(', ');
      if (formPayload.other_degree) {
        formPayload.degree = formPayload.degree + ', ' + formPayload.other_degree;
      }

      if (formPayload.role?.toUpperCase() === 'OTH') {
        formPayload.role = formPayload.other_role;
      }

      if (meetingInfo.is_virtual_hybrid) {
        formPayload.is_virtual_attendee = attendanceType === 'virtual' ? 1 : 0;
      }

      const req = await fetch(`${baseApiUrl}/api/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...formPayload, contactId: npiInfo.contactid })
      });
      if (req.status === 201) {
        setSubmitted(true);
      } else if (req.status === 403) {
        setSubmitError('This meeting is at capacity.');
      } else if (req.status === 401) {
        setErrMsg(
          'We&apos;re sorry, but you have already attended or registered for the maximum number of programs allowed by Veloxis. Please contact your local Transplant Account Manager or HealthLogix at <a href="mailto:VeloxisSB@hlxusa.com">VeloxisSB@hlxusa.com</a> if you have any questions or believe this may be an error.'
        );
        setErrModalOpen(true);
      } else if (req.status === 406) {
        setErrMsg(
          'We&apos;re sorry, but you are unable to attend this program. Please contact your local Transplant Account Manager or HealthLogix (<a href="mailto:VeloxisSB@hlxusa.com">VeloxisSB@hlxusa.com</a>) if you have any questions or believe this may be an error.'
        );
        setErrModalOpen(true);
      } else {
        setSubmitError('Something went wrong with your submission.');
      }
    }
  };

  const toggleErrModal = () => {
    // this is just used to close the modal for now
    setErrModalOpen(!errModalOpen);
    setErrMsg('');
  };

  const renderErrorModal = () => {
    return (
      <Dialog open={errModalOpen} onClose={toggleErrModal}>
        <>
          <div style={{ position: 'absolute', right: 5, top: 5, cursor: 'pointer' }}>
            <CloseOutlined onClick={toggleErrModal} />
          </div>
          <div
            style={{
              backgroundColor: 'white',
              padding: 25,
              maxWidth: 'md',
              textAlign: 'center',
              margin: 'auto'
            }}
          >
            <p style={{ fontSize: 20 }}>
              <span dangerouslySetInnerHTML={{ __html: errMsg }} />
            </p>
            <Button
              onClick={toggleErrModal}
              style={{ marginTop: 20, color: 'white', backgroundColor: 'black' }}
              className="hlx-button "
              variant="contained"
            >
              Close
            </Button>
          </div>
        </>
      </Dialog>
    );
  };

  const updateFormField = (field) => {
    return (event) => {
      if (_.includes(['email_consent', 'hcp_certify'], field)) {
        return setFormValues({ ...formValues, [field]: event.target.checked });
      }
      setFormValues({ ...formValues, [field]: event.target.value });
    };
  };

  const onVirtualHybridChange = (label) => {
    return (e) => {
      if (e.target.checked) {
        // console.log('lavel here', label);
        setAttendanceType(label);
      }
    };
  };

  const updateSelectMultiple = (field) => {
    return (values) => {
      setFormValues({ ...formValues, [field]: values });
    };
  };

  const renderNpiFields = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <TextField
            disabled={Boolean(npiInfo && /^\d{10}$/.test(npiDataObj.npi))}
            fullWidth
            style={{ margin: 10 }}
            value={formValues.npi || ''}
            onChange={updateFormField('npi')}
            label={'*NPI'}
            error={formErrors.npi}
            margin={'dense'}
            variant={'outlined'}
            type="number"
          />
        </div>
        <div style={{ width: '50%', display: 'flex' }} className="mobile-portrait-width-unset">
          <FormControl fullWidth style={{ margin: 10 }}>
            <InputLabel htmlFor="state">Licensed State</InputLabel>
            <MuiSelect
              native
              variant={'outlined'}
              value={formValues.licensed_state || ''}
              onChange={updateFormField('licensed_state')}
              margin={'dense'}
              label="Licensed State"
              inputProps={{
                name: 'licensed_state',
                id: 'licensed_state',
                maxLength: 2
              }}
            >
              {/* <option aria-label="None" key="none" value="" /> */}
              {_.map(dropDownValues.states, (state) => {
                return (
                  <option key={state.abrv} value={state.abrv}>
                    {state.name}
                  </option>
                );
              })}
            </MuiSelect>
          </FormControl>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.license_number || ''}
            onChange={updateFormField('license_number')}
            label={'License #'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 30 }}
          />
        </div>
      </div>
    );
  };

  if (submitted) {
    return <h2>Thank you for submitting your registration!</h2>;
  }

  return (
    <div>
      {renderErrorModal()}
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            disabled={Boolean(npiInfo && npiDataObj.firstname)}
            style={{ margin: 10 }}
            value={formValues.firstname || ''}
            onChange={updateFormField('firstname')}
            label={'*First Name'}
            margin={'dense'}
            variant={'outlined'}
            error={formErrors.firstname}
            inputProps={{ maxLength: 35 }}
          />
          <TextField
            fullWidth
            disabled={Boolean(npiInfo && npiDataObj.minitial)}
            style={{ margin: 10 }}
            value={formValues.minitial || ''}
            onChange={updateFormField('minitial')}
            label={'Middle Name Initial'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 1 }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            disabled={Boolean(npiInfo && npiDataObj.lastname)}
            style={{ margin: 10 }}
            value={formValues.lastname || ''}
            onChange={updateFormField('lastname')}
            label={'*Last Name'}
            margin={'dense'}
            variant={'outlined'}
            error={formErrors.lastname}
            inputProps={{ maxLength: 35 }}
          />
          <TextField
            fullWidth
            disabled={Boolean(npiInfo && npiDataObj.suffix)}
            style={{ margin: 10 }}
            value={formValues.suffix || ''}
            onChange={updateFormField('suffix')}
            label={'Suffix'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 75 }}
          />
        </div>
        <div style={{ display: 'flex', marginTop: 10 }} className="mobile-column-reverse-switch">
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <FormControl style={{ marginLeft: 10, marginRight: 10, display: 'block' }}>
              <SelectMultiField
                values={formValues.specialty}
                label={'*Specialty'}
                options={_.map(dropDownValues.specialties, (specialty) => ({
                  value: specialty.name,
                  label: specialty.name
                }))}
                onChange={updateSelectMultiple('specialty')}
                error={formErrors.specialty}
              />
            </FormControl>
            {_.find(
              formValues.specialty || [],
              (spclty) => spclty.value.toUpperCase() === 'OTHER'
            ) && (
              <TextField
                fullWidth
                style={{ margin: 10, paddingRight: 20 }}
                value={formValues.other_specialty || ''}
                onChange={updateFormField('other_specialty')}
                label={'*Other Specialty'}
                error={formErrors.other_specialty}
                margin={'dense'}
                variant={'outlined'}
                inputProps={{ maxLength: 50 }}
              />
            )}
            <FormHelperText style={{ marginLeft: 15 }}>
              If you cannot locate your specialty from this drop-down menu, please contact your
              local sales representative, as you may not be permitted to attend this program.{' '}
            </FormHelperText>
          </div>
          {isLicensed && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              <FormControl
                style={{
                  display: 'block',
                  margin: '2px 10px 5px 10px'
                }}
              >
                <SelectMultiField
                  values={formValues.degree}
                  label={'*Degree'}
                  options={_.map(dropDownValues.degrees, (degree) => ({
                    value: degree.name,
                    label: degree.name
                  }))}
                  onChange={updateSelectMultiple('degree')}
                  error={formErrors.degree}
                />
              </FormControl>
              {_.find(
                formValues.degree || [],
                (degree) => degree.value.toUpperCase() === 'OTHER'
              ) && (
                <TextField
                  fullWidth
                  style={{ margin: 10, paddingRight: 20 }}
                  value={formValues.other_degree || ''}
                  onChange={updateFormField('other_degree')}
                  label={'*Other Degree'}
                  error={formErrors.other_degree}
                  margin={'dense'}
                  variant={'outlined'}
                  inputProps={{ maxLength: 50 }}
                />
              )}
            </div>
          )}
        </div>
        {!isLicensed && (
          <div
            style={{ display: formValues.role?.toUpperCase() === 'OTH' && 'flex' }}
            className="mobile-column-switch"
          >
            <div
              style={{
                padding: 10,
                width: formValues.role?.toUpperCase() === 'OTH' ? '100%' : '50%'
              }}
              className="mobile-role-width-switch"
            >
              <FormControl fullWidth>
                <InputLabel error={formErrors.role} htmlFor="role">
                  *Identify the role that best describes you
                </InputLabel>
                <MuiSelect
                  native
                  variant={'outlined'}
                  value={formValues.role}
                  onChange={updateFormField('role')}
                  error={formErrors.role}
                  label="*Identify the Role that best describes you"
                  margin={'dense'}
                  inputProps={{
                    name: 'role',
                    id: 'role',
                    maxLength: 50
                  }}
                >
                  <option aria-label="None" key="none" value="" />
                  {_.map(
                    _.filter(dropDownValues.roles, (rl) => rl.subcatid !== 6),
                    (role) => {
                      return (
                        <option key={role.subcatid} value={role.descriptionshort}>
                          {role.description}
                        </option>
                      );
                    }
                  )}
                </MuiSelect>
              </FormControl>
            </div>
            {formValues.role?.toUpperCase() === 'OTH' && (
              <TextField
                style={{ margin: 10, minWidth: '50%' }}
                value={formValues.other_role || ''}
                onChange={updateFormField('other_role')}
                label={'*Other Role'}
                error={formErrors.other_role}
                margin={'dense'}
                variant={'outlined'}
                inputProps={{ maxLength: 100 }}
              />
            )}
          </div>
        )}

        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.orgname || ''}
            onChange={updateFormField('orgname')}
            label={'*Affiliation. Please enter "NA" if not applicable.'}
            error={formErrors.orgname}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 100 }}
          />
        </div>

        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.address1 || ''}
            onChange={updateFormField('address1')}
            label={'*Office Address'}
            error={formErrors.address1}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.address2 || ''}
            onChange={updateFormField('address2')}
            label={'Address 2'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 50 }}
          />
        </div>
        <div className="flex mobile-column-switch">
          <TextField
            style={{ margin: 10, minWidth: '30%' }}
            value={formValues.city || ''}
            onChange={updateFormField('city')}
            label={'*City'}
            error={formErrors.city}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 50 }}
          />
          <div className="flex" style={{ minWidth: '50%' }}>
            <FormControl style={{ margin: 10, flex: '1 50px' }}>
              <InputLabel error={formErrors.state} htmlFor="state">
                *State
              </InputLabel>
              <MuiSelect
                native
                variant={'outlined'}
                value={formValues.state || ''}
                onChange={updateFormField('state')}
                error={formErrors.state}
                margin={'dense'}
                label="*State"
                inputProps={{
                  name: 'state',
                  id: 'state',
                  maxLength: 2
                }}
              >
                {_.map(dropDownValues.states, (state) => {
                  return (
                    <option key={state.abrv} value={state.abrv}>
                      {state.name}
                    </option>
                  );
                })}
              </MuiSelect>
            </FormControl>
            <TextField
              fullWidth
              style={{ margin: 10, flex: '1 100px' }}
              value={formValues.zip || ''}
              onChange={updateFormField('zip')}
              label={'*Zip'}
              error={formErrors.zip}
              margin={'dense'}
              variant={'outlined'}
              inputProps={{ minLength: 5, maxLength: 10 }}
            />
          </div>
        </div>
        {isLicensed && renderNpiFields()}
        {/* {!isLicensed && renderStateLicenseNumber()} */}
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.phone || ''}
            onChange={updateFormField('phone')}
            label={'*Office Phone'}
            error={formErrors.phone}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 15 }}
            type="tel"
          />
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.fax || ''}
            onChange={updateFormField('fax')}
            label={'Fax'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 15 }}
            type="tel"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.home_phone || ''}
            onChange={updateFormField('home_phone')}
            label={'Home Phone'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 15 }}
            type="tel"
          />
          <TextField
            fullWidth
            style={{ margin: 10 }}
            value={formValues.email || ''}
            onChange={updateFormField('email')}
            label={'*Email'}
            error={formErrors.email}
            helperText={formErrors.email && 'Invalid email address'}
            margin={'dense'}
            variant={'outlined'}
            inputProps={{ maxLength: 100 }}
            type="email"
          />
        </div>
        {meetingInfo.meetingtype !== 'Fully Virtual' && (
          <div style={{ padding: 10 }}>
            <TextField
              className="multiLineTextField"
              fullWidth
              multiline
              rows={5}
              value={formValues.dietary_constraints || ''}
              onChange={updateFormField('dietary_constraints')}
              InputLabelProps={{
                style: { fontSize: 14, paddingRight: 5 }
              }}
              label={'Dietary Constraints, optional, not all programs include a meal'}
              variant={'outlined'}
              inputProps={{ maxLength: 50 }}
            />
          </div>
        )}

        <div style={{ padding: 10 }}>
          <TextField
            className="multiLineTextField"
            fullWidth
            multiline
            rows={5}
            value={formValues.comments || ''}
            InputLabelProps={{
              style: { fontSize: 14 }
            }}
            onChange={updateFormField('comments')}
            label={'Comments'}
            variant={'outlined'}
            inputProps={{ maxLength: 100 }}
          />
        </div>
        <div style={{ paddingLeft: 10 }}>
          <FormControl required error={formErrors.hcp_certify}>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={formValues.hcp_certify || false}
                  onChange={updateFormField('hcp_certify')}
                  name="hcp_certify"
                />
              }
              label="I certify I am a Healthcare provider involved in the care or treatment of Adult Renal Transplant Recipients. If not involved in the care or treatment of these patients, please refrain from joining this program. "
            />
            {formErrors.hcp_certify && <FormHelperText>This field is required.</FormHelperText>}
          </FormControl>
        </div>
        <div style={{ paddingLeft: 10, marginBottom: 20 }}>
          <FormControl required error={formErrors.email_consent}>
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  checked={formValues.email_consent || false}
                  onChange={updateFormField('email_consent')}
                  name="email_consent"
                />
              }
              label="I give my consent to use my email address for communications involving this event"
            />
            {formErrors.email_consent && <FormHelperText>This field is required.</FormHelperText>}
          </FormControl>
        </div>
        {meetingInfo.is_virtual_hybrid && (
          <div style={{ paddingLeft: 10, marginBottom: 20 }}>
            <FormControl required error={formErrors.video_consent}>
              <span style={{ maxWidth: 750 }}>
                The program you are registering for is hybrid and can accomodate both in-person and
                virtual attendees. <br />
                Please confirm how you are planning to attend by choosing from the options below.
              </span>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={attendanceType === 'in-person'}
                      onChange={onVirtualHybridChange('in-person')}
                      name="is_virtual_hybrid"
                    />
                  }
                  label="In-person"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="default"
                      checked={attendanceType === 'virtual'}
                      onChange={onVirtualHybridChange('virtual')}
                      name="is_virtual_hybrid"
                    />
                  }
                  label="Virtual"
                />
              </FormGroup>
              {formErrors.attendanceType && (
                <FormHelperText style={{ color: '#f44336' }}>
                  This field is required.
                </FormHelperText>
              )}
            </FormControl>
          </div>
        )}
      </div>
      {submitError && (
        <p style={{ color: 'red', textAlign: 'center', fontSize: 20 }}>{submitError}</p>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          style={{ width: '125px' }}
          className="hlx-button"
          variant="contained"
          onClick={() => submitForm()}
        >
          Register
        </Button>
        <Button
          className="cancel-btn hlx-button"
          variant="contained"
          onClick={() => setData({ npiInfo: null, isLicensed: null })}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  const { dropDownValues, isLicensed, npiInfo, meetingInfo, regSource } = data;
  return { dropDownValues, isLicensed, npiInfo, meetingInfo, regSource };
};
export default connect(mapStateToProps, { setData })(VeloxisRegFormComponent);
