import React from 'react';

import GenericFormComponent from './GenericFormComponent';
import HarmonyRegFormComponent from './HarmonyRegFormComponent';
import MelintaRegFormComponent from './MelintaRegFormComponent';
import VeloxisRegFormComponent from './VeloxisRegFormComponent';
import VericelRegFormComponent from './VericelRegFormComponent';

const regFormMapping = {
  harmony: HarmonyRegFormComponent,
  melinta: MelintaRegFormComponent,
  veloxis: VeloxisRegFormComponent,
  vericel: VericelRegFormComponent
};

const renderRegComponent = (tenant) => {
  console.log('renderRegComponent tenant', tenant);
  const RegFormComponent = regFormMapping[tenant] ? regFormMapping[tenant] : GenericFormComponent;
  return <RegFormComponent />;
};

export default renderRegComponent;
